import Navbar from "./components/Navbar/Navbar.jsx";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Register from "./components/auth/Register";
import Login from "./components/auth/Login";
import Article from "./components/Article/Article";
import Create from "./components/Create/Create.jsx";
import Home from "./components/Home/Home";
import Support from "./components/Support/Support";
import Privacypolicy from "./components/Privacypolicy/Privacypolicy";
import Blogs from "./components/Blogs/Blogs.js";
import Tools from "./components/Tools/Tools.js";
import PostTool from "./components/Tools/PostTool/PostTool";
import Tool from "./components/Tools/Tool/Tool.js";
import Aboutus from "./components/Home/Aboutus/Aboutus.js";
import Fourzerofour from "./components/common/Fourezerofour/Fourzerofour.js"; // Import the NotFound component
import { Helmet } from "react-helmet";
import transitions from "bootstrap";

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/aboutus" element={<Aboutus />} />
        <Route path="/article/:slug" element={<Article />} />
        <Route path="/create" element={<Create />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/" element={<Home />} />
        <Route path="/support" element={<Support />} />
        <Route path="/privacypolicy" element={<Privacypolicy />} />
        <Route path="/tools" element={<Tools />} />
        <Route path="/posttool" element={<PostTool />} />
        <Route path="/tool/:id" element={<Tool />} />

        {/* Add the 404 route at the bottom */}
        <Route path="*" element={<Fourzerofour />} /> {/* Catch-all for undefined routes */}
      </Routes>
    </Router>
  );
}

export default App;
