import React from 'react'
import About from '../About/About'
import Toolsintro from '../Toolsintro/Toolsintro'
import { Helmet } from 'react-helmet';
function Aboutus() {
    return (
        <div>
            <Helmet>
                <title>About Us</title>
                <meta name="description" content="Discover AILogixX.com, where artificial intelligence meets natural intellect. 
                Explore our community of AI professionals, insightful blogs, and the latest AI advancements. 
                Join us to embrace cutting-edge technology and contribute to the future of AI." />
                <meta name="keywords" content="Tools, Key Features, Category, Official Links"/>
            </Helmet>
            <About /><Toolsintro /></div>
    )
}

export default Aboutus