import React, { useState } from "react";
import { Timestamp, collection, addDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage, db, auth } from "../Source/firebaseConfig";
import { toast } from "react-toastify";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
export default function Support() {

  const [formData, setFormData] = useState({
    Author: "",
    title: "",
    description: "",
    image: "",
    createdAt: Timestamp.now().toDate(),
  });

  const [progress, setProgress] = useState(0);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleImageChange = (e) => {
    setFormData({ ...formData, image: e.target.files[0] });
  };

  const handlePublish = () => {
    if (!formData.title || !formData.description || !formData.image) {
      alert("Please fill all the fields");
      return;
    }

    const storageRef = ref(
      storage,
      `/images/${Date.now()}${formData.image.name}`
    );

    const uploadImage = uploadBytesResumable(storageRef, formData.image);

    uploadImage.on(
      "state_changed",
      (snapshot) => {
        const progressPercent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(progressPercent);
      },
      (err) => {
        console.log(err);
      },
      () => {
        setFormData({
          title: "",
          description: "",
          image: "",
          Author: "",
        });

        getDownloadURL(uploadImage.snapshot.ref).then((url) => {
          const articleRef = collection(db, "Supportres");
          addDoc(articleRef, {
            title: formData.title,
            description: formData.description,
            imageUrl: url,
            createdAt: Timestamp.now().toDate(),


            Author: formData.Author,
          })
            .then(() => {
              toast("Your Response send to Support Team", { type: "success" });
              setProgress(0);
            })
            .catch((err) => {
              toast("TrY Again", { type: "error" });
            });
        });
      }
    );
  };

  return (
    <><Helmet>
      <title>Contactus</title>
      <meta name="description" content="Browse through our latest articles covering a wide range of topics." />
    </Helmet> <div className="container">
        <div className=" p-3 mt-3 " >

          <>

            <h2 className="secoundparm" style={{ color: "#5e4610" }}>Custommer Support</h2>
            <div className="form-group">
              <label htmlFor="" >Name</label>
              <input
                type="text"
                name="Author"
                className="form-control"
                value={formData.Author}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="" >Email</label>
              <input
                type="text"
                name="title"
                className="form-control"
                value={formData.title}
                onChange={(e) => handleChange(e)}
              />
            </div>

            {/* description */}
            <label htmlFor="">Write your Problem</label>
            <textarea
              name="description"
              className="form-control"
              cols={5}
              rows={5}
              value={formData.description}
              onChange={(e) => handleChange(e)}
            />

            {/* image */}
            <label htmlFor="">Add A ScreenShoot</label>
            <input
              type="file"
              name="image"
              accept="image/*"
              className="form-control"
              onChange={(e) => handleImageChange(e)}
            />

            {progress === 0 ? null : (
              <div className="progress" style={{ height: "30px" }}>
                <div
                  className="progress-bar progress-bar-striped mt-2"
                  style={{ width: `${progress}%` }}
                >
                  {`uploading  ${progress}%`}
                </div>
              </div>
            )}
            <button
              className="form-control btn-primary mt-2  "
              onClick={handlePublish}
            >send
            </button>
          </>


        </div ><p style={{ color: "red" }}>Email</p><p>www.hello@ailogixx.com</p>
        <hr />

      </div></>

  );
}
