import React from 'react'
import './Home.css'
import Footer from '../Footer/Footer'
import Hero from './Hero/Hero'
import { Helmet } from 'react-helmet';

import Recenttools from "./Recenttools/Recenttools"
import LatestBlogs from './LatestBlogs/LatestBlogs'

function Home() {
    return (

        <div>
            <Helmet>
                <title>AILogixX - Where AI Meets Human Intellect</title>
                <meta name="description" content="Where Artificial Intelligence meets human intellect. 
                We welcome all individuals and AI enthusiasts to explore cutting-edge innovations and discussions in the world of AI." />
                <meta name="keywords" content="Artificial Intellect, AI Community, Human Intelligence, AI Integration, Tech Innovation, Smart Solutions, 
                AI Collaboration, AI Insights, Cognitive Technology, Future Intelligence, Machine Learning, AI Discussions, AI Resources, AI Ecosystem, Digital Minds"/>
            </Helmet>
            <Hero />
            <LatestBlogs />
            <Recenttools />
            <Footer />

        </div >
    )
}

export default Home