import { doc, onSnapshot } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useParams } from "react-router-dom";
import Footer from "../../Footer/Footer";
import { auth, db } from "../../Source/firebaseConfig";
import { Helmet } from "react-helmet";
import './Tool.css';

export default function Tool() {
    const { id } = useParams();
    const [article, setArticle] = useState(null);
    const [imageLoaded, setImageLoaded] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [user] = useAuthState(auth);

    useEffect(() => {
        const docRef = doc(db, "Tools", id);

        const unsubscribe = onSnapshot(docRef, (snapshot) => {
            if (snapshot.exists()) {
                const data = snapshot.data();
                console.log('Fetched Data:', data);
                setArticle({ ...data, id: snapshot.id });
                setLoading(false);
            } else {
                setError('Article not found');
                setLoading(false);
            }
        }, (error) => {
            setError(error.message);
            setLoading(false);
        });

        return () => unsubscribe();
    }, [id]);

    const handleImageLoad = () => {
        setImageLoaded(true);
    };

    const renderBreadcrumbs = () => (
        <div className="breadcrumb">
            <Link to="/" className="breadcrumb-link">Home</Link> /
            <Link to="/Tools" className="breadcrumb-link">Tools</Link>
        </div>
    );

    const renderLoading = () => (
        <div className="loading-message">Loading...</div>
    );

    const renderError = () => (
        <div className="error-message">Error: {error}</div>
    );

    const renderArticle = () => (
        <div className="tool-content" data-aos="fade-up" data-aos-delay="100">
            <article>
                <div className="tool-image-wrapper">
                    {!imageLoaded && <div className="image-placeholder">Loading image...</div>}
                    <img
                        src={article.imageUrl}
                        alt={article.title}
                        className={`tool-image ${imageLoaded ? "image-loaded" : ""}`}
                        onLoad={handleImageLoad}
                    />
                </div>
                <h2 className="tool-title">{article.title}</h2>
                <p className="tool-category">{article.Type}</p>
                <div className="tool-content" dangerouslySetInnerHTML={{ __html: article.content || '' }} />
                <h5 className="tool-section-title">Official Link</h5>
                <a href={article.OfficalLink} target="_blank" rel="noopener noreferrer" className="tool-link">
                    <i className="fa fa-paper-plane" aria-hidden="true"></i> VISIT
                </a>
            </article>
        </div>
    );

    return (
        <>
            <Helmet>
                <title> Tools - AILogixX</title>
                <meta name="description" content="Explore our tools section featuring innovative tools designed to enhance your productivity and efficiency at AILogixX." />
            </Helmet>
            <div className="container">
                {renderBreadcrumbs()}
                {loading && renderLoading()}
                {error && renderError()}
                {article && !loading && renderArticle()}
            </div>
            <Footer />
        </>
    );
}
