import React from "react";
import { Link } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../Source/firebaseConfig";
import './Navbar.css';  // Adjust the path if necessary
const Bottombar = () => {
  const [user] = useAuthState(auth);

  return (
    <div className="simple-list-container">
      <ul className="simple-list">

        <li>
          <Link className="simple-link" to="/Privacypolicy">Privacy Policy</Link>
        </li>
        <li>
          <Link className="simple-link" to="/PostTool">Add a Tool</Link>
        </li>
        <li>
          <Link className="simple-link" to="/Create">Write a Blog</Link>
        </li>
      </ul>
    </div>
  );
};

export default Bottombar;
