// src/pages/NotFound.js
import React from 'react';
import './Fourzerofour.css'; // We will define styles here

const Fourzerofour = () => {
    return (
        <div className="not-found-container">
            <div className="not-found-content">
                <h1>404</h1>
                <h2>Page Not Found</h2>
                <p>The page you are looking for does not exist or has been moved.</p>
                <a href="/" className="back-home-button">
                    Go Back to Home
                </a>
            </div>
        </div>
    );
};

export default Fourzerofour;
