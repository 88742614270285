import React from 'react'
import "./About.css"
import Aboutimage from "../About/sdfdssdfsdfsdf.jpg"
function About() {
    return (
        <div id="about" className="about">
            
            <div className="container" data-aos="fade-up">
                <div className="row">
                    <div className="col-lg-6 order-1 order-lg-2" data-aos="fade-left" data-aos-delay="100">
                        <img src={Aboutimage} className="img-fluid" alt="yellow-pen" />
                    </div>
                    <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">

                        <h2 style={{ color: "#f1c40f" }}>AILogixX</h2>
                        <p classNames="fst-italic">
                            The AILogixX.com is the zone where ARTIFICIAL INTELLIGENC and natural Intellect interfaces. Members of AILogixX.com welcome all individuals and Intellects utilizing Artificial Intelligence. At AILogixX.com you will discover
                        </p>
                        <ul>
                            <li><i className="bi bi-check-circle"></i>"Populace working on Artificial Intelligence"</li>
                            <li><i className="bi bi-check-circle"></i>"Blogs Written by Pro Artificial Intelligence Originators"</li>
                            <li><i className="bi bi-check-circle"></i> "Upcoming Devise in Artificial Intelligence"</li>
                        </ul>
                        <p>
                            We definitely encourage you to bring new technology advanced in level called Artificial Intelligence which will eventually help everyone to join our community. We invite you in our 'media' section, where you can read the words of vast worth from our AIlogixX individuals. And handset, we encourage you to embrace this mingle with us by sharing your ideas.
                        </p>

                    </div>
                </div>

            </div>
        </div>
    )
}

export default About